const env = process.env.NODE_ENV;
let config =  require('./local');

switch (env) {
  case 'production':
    config = require('./production')
    break;
  case 'staging':
    config = require('./staging')
    break;
  case 'demo':
    config = require('./demo')
    break;
  case 'qa':
      config = require('./qa')
      break;
  case 'development':
    config = require('./development')
    break;
  default:
      config =  require('./local');
}
module.exports = config;
